import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  connect() {
    useIntersection(this, { threshold: [0.25, 0.5, 1.0] })
  }

  appear() {
    this.element.play()
  }

  disappear() {
    this.element.pause()
  }
}
